<!--
 * @Author: ocean
 * @Description: 数据统计 充电数据统计 电站统计列表
-->
<template>
  <scroll-layout class="powerstationStatistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParam"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div class="wrap-box">
      <div class="tags" v-show="switchOver">
        报表统计口径说明
        <span @click="tagClick()">?</span>
      </div>
      <div v-if="!switchOver">
        <div class="but-box">
          <!-- <ykc-button type="plain" @click="switchOverFn(1)">报表模式</ykc-button> -->
        </div>
        <statistics-chart
          ref="statisticsChart"
          :page="'platform'"
          :title="'充电趋势'"
          :isUpdate="isUpdate"
          :searchParams="searchParam"
          :chargingTrendKey="'queryStationChargingTrendList'"></statistics-chart>
      </div>
      <div v-if="switchOver">
        <ykc-table
          ref="YkcTable"
          operateFixedType="right"
          :data="tableData"
          :title="tableTitle"
          :columns="tableColumns"
          :operateButtons="tableOperateButtons">
          <template #headerButtons>
            <div class="table-header-button">
              <ykc-button v-rbac="'data:charge:station:export'" type="plain" @click="doExportFile">
                导出
              </ykc-button>
              <!-- <ykc-button type="plain" @click="switchOverFn(2)">图表模式</ykc-button> -->
            </div>
          </template>
          <ykc-pagination
            align="right"
            slot="pagination"
            ref="YkcPagination"
            :total="pageInfo.total"
            :page-size.sync="pageInfo.size"
            :current-page.sync="pageInfo.current"
            @current-change="handlePaginationCurrentChange"
            @size-change="handlePaginationSizeChange"></ykc-pagination>
        </ykc-table>
        <ykc-operation-dialog
          size="large"
          title="统计口径说明"
          confirm-btn-txt="同意"
          :show-footer="false"
          :show.sync="operationStatus">
          <div slot="content">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              :autosize="{ minRows: 2 }"
              v-model="textarea2"></el-input>
          </div>
        </ykc-operation-dialog>
      </div>
    </div>
    <div class="bottomBtn" @click="switchOverFn">
      <div><i :class="switchOver ? 'el-icon-s-data' : 'el-icon-s-grid'"></i></div>
      <div>{{ switchOver ? '图表模式' : '列表模式' }}</div>
    </div>
  </scroll-layout>
</template>
<script>
  import { getLastTime, offlineExport } from '@/utils';
  import { treeUtils, barrierPage, operatorData, dataStatisticsServe } from '@/service/apis';

  import statisticsChart from '../../components/dataStatisticsChart.vue';

  export default {
    name: 'powerstationStatisticsList',
    components: {
      statisticsChart,
    },
    data() {
      return {
        switchOver: true,
        topTip:
          '温馨提示：由于统计数据量过大，默认显示当日统计数据，需要查看其他数据请使用查询功能。',
        tradeNo: null,
        isUpdate: false,
        topTipStatus: true,
        operationStatus: false,
        addressData: [], // 区域数据组件使用数据源
        stationIdData: [], // 归属电站组件使用数据源
        userOperatorList: [], // 归属商户组件使用数据源
        searchParam: {
          date: {},
          cityId: '',
          endTime: '',
          startTime: '',
          dateType: '1',
          userCompanyId: '',
          stationIdList: [],
          stationOperatorList: [],
        },
        tableTitle: '明细列表', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          {
            label: '电站名称',
            prop: 'stationName',
            fixed: true,
            width: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div onClick={() => this.handleRowClick(row)}>
                    <span style="{ color: blue, cursor: pointer }">{row.stationName}</span>
                  </div>
                );
              },
            },
            id: 1,
          },
          { label: '归属商户', prop: 'stationOperatorName', minWidth: '150px', id: 2 },
          { label: '城市', prop: 'cityName', minWidth: '150px', id: 3 },
          { label: '电量(度)', prop: 'chargedPower', minWidth: '150px', id: 4 },
          { label: '充电时长(h)', prop: 'chargedTime', minWidth: '150px', id: 5 },
          { label: '充电次数', prop: 'chargedCount', minWidth: '150px', id: 6 },
          { label: '电费(元)', prop: 'chargedAmount', minWidth: '150px', id: 7 },
          { label: '服务费(元)', prop: 'serviceAmount', minWidth: '150px', id: 8 },
          { label: '订单总金额(元)', prop: 'totalAmount', minWidth: '160px', id: 9 },
          { label: '电费实付(元)', prop: 'chargedAmountDiscount', minWidth: '160px' },
          { label: '服务费实付(元)', prop: 'parkedAmountDiscount', minWidth: '160px' },
          {
            label: '用户实付合计(元)',
            prop: 'chargedAmountAfterDiscount',
            minWidth: '160px',
            id: 10,
          },
          { label: '引流抽成费用(元)', prop: 'commissionAmount', minWidth: '160px', id: 11 },
          { label: '商户实收合计(元)', prop: 'operatorActualIncome', minWidth: '160px', id: 12 },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        textarea2: `电量(度)：所有历史订单电量汇总
  充电时长(h)：所有历史订单充电时长汇总
  充电次数：订单总数量
  电费(元)：所有历史订单原价电费
  服务费(元)：所有历史订单原价服务费
  订单总金额(元)：所有历史订单原价总金额
  用户实付合计(元)：订单总金额- 平台卡券优惠- 平台活动优惠-商户卡券优惠-商户活动优惠-会员折扣优惠
  引流抽成费用(元)：所有历史订单引流抽成总金额
  商户实收合计(元)：订单总金额-商户卡券优惠-商户活动优惠-引流抽成费用`,
      };
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.startTime = startDate;
      this.searchParam.endTime = endDate;

      this.requestGetDistrict();
      this.requestUserOperatorList();
      this.requestStationIdDataList();
      this.requestList();
    },
    computed: {
      searchData() {
        return [
          {
            key: 'date',
            isDateType: false,
            label: '时间维度',
            comName: 'YkcDatePicker',
          },
          {
            multiple: true,
            label: '电站名称',
            key: 'stationIdList',
            comName: 'YkcDropdown',
            filterable: true,
            placeholder: '请选择电站名称',
            data: this.stationIdData,
            // configSet: {
            //   label: 'stationName',
            //   value: 'stationId',
            // },
          },
          {
            multiple: true,
            label: '商户名称',
            comName: 'YkcDropdown',
            key: 'stationOperatorList',
            placeholder: '请选择商户名称',
            data: this.userOperatorList,
            // configSet: {
            //   label: 'name',
            //   value: 'operatorId',
            // },
          },
          {
            comName: 'YkcCascader',
            key: 'cityIds',
            label: '城市选择',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
          },
        ];
      },
    },
    methods: {
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属商户数据
       */
      requestUserOperatorList() {
        operatorData
          .userOperatorList({})
          .then(res => {
            console.log('获取归属商户数据+++++', res);
            res.map(item => {
              return this.userOperatorList.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属电站数据
       */
      requestStationIdDataList() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
            console.log('获取归属电站数据+++++', this.stationIdData);
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const reqParams = {
          ...pageInfos,
          cityId: this.searchParam.cityId,
          endTime: this.searchParam.endTime,
          startTime: this.searchParam.startTime,
          dateType: this.searchParam.dateType,
          userCompanyId: this.searchParam.userCompanyId,
          stationIdList: this.searchParam.stationIdList.map(String),
          stationOperatorList: this.searchParam.stationOperatorList.map(String),
        };
        dataStatisticsServe
          .stationChargingDetailList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 统计说明
       */
      tagClick() {
        this.operationStatus = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'platform_station_charging_trend',
          jsonNode: {
            ...this.searchParam,
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParam.startTime, this.searchParam.endTime] = searchData.date;
        }

        // 城市选择
        if (
          searchData.cityIds &&
          Array.isArray(searchData.cityIds) &&
          searchData.cityIds.length > 0
        ) {
          [, this.searchParam.cityId] = this.searchParam.cityIds;
        }

        // 商户字段处理选择
        if (
          searchData.companyId &&
          Array.isArray(searchData.companyId) &&
          searchData.companyId.length > 0
        ) {
          [, this.searchParam.userCompanyId] = this.searchParam.companyId;
        }

        this.switchOver ? this.requestList() : this.$refs.statisticsChart.getCharts();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);

        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.startTime = startDate;
        this.searchParam.endTime = endDate;
        this.searchParam.dateType = '1';
      },
      /**
       * 跳转电站
       */
      handleRowClick(row) {
        let url = '/plantAssets/powerStationManagement/directConnectionStation/Detail';
        if (row.stationType === '1') {
          url = '/plantAssets/powerStationManagement/directConnectionStation/Detail';
        } else if (row.stationType === '2') {
          url = '/plantAssets/powerStationManagement/interconnectionStation/Detail';
        }
        this.$router.push({
          path: url,
          query: { stationId: row.stationId },
        });
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 切换到报表或图表模式
       */
      switchOverFn() {
        this.switchOver = !this.switchOver;
        console.log(this.switchOver);
        if (this.switchOver) {
          this.requestList();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .but-box {
    display: flex;
    justify-content: flex-end;
  }
  .powerstationStatistics-list {
    .wrap-box {
      position: relative;
      .tags {
        display: flex;
        align-items: center;
        font-size: 12px;
        position: absolute;
        top: 20px;
        left: 85px;
        > span {
          display: block;
          width: 16px;
          height: 16px;
          margin-left: 8px;
          font-size: 10px;
          line-height: 16px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          border-radius: 50%;
          background: blue;
        }
      }

      .ykc-table {
        .el-table .el-table__row {
          .cell .userStatus {
            padding-left: 8px;
            position: relative;
          }

          .cell .userStatus::after {
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #d0021b;
            position: absolute;
            top: 5.5px;
            left: 0;
          }

          .cell .status-blue::after {
            background: #4d8cfd;
          }

          .cell .status-green::after {
            background: #02c498;
          }

          .cell .status-black::after {
            background: #9b9b9b;
          }
        }

        .sealBtn {
          color: #4d8cfd;
          cursor: pointer;
        }
      }
    }
    .bottomBtn {
      position: absolute;
      right: 10px;
      bottom: 20px;
      width: 90px;
      height: 45px;
      border-radius: 10px;
      color: #409eff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
      background-color: #fff;
      padding-right: 5px;
      cursor: pointer;
      z-index: 1000;

      i {
        margin: 0 5px;
      }
      &:hover {
        background-color: #f2f6fc;
      }
    }
  }
</style>
